import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SetCurrentApp } from '@app/core/states/app.actions';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import {
  AddConfigAsset,
  FetchAppConfigRequest,
  PostAssetsRequest,
  TogglePayingPublicationAddon,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import {
  AppConfigAssets,
  AppConfigurationState,
} from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import {
  SkipToStep,
  UpdateTooltipMessage,
} from '@app/features/app-creation-and-configuration/states/stepper.actions';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subscription,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs';
import { UnsavedChangesService } from '../../services/unsaved-changes.service';
import { StepperState } from '../../states/stepper.state';
import { PublicationAddonDialogComponent } from '../plan/publication-addon-dialog/publication-addon-dialog.component';

@Component({
  selector: 'rk-assets-management',
  templateUrl: './assets-management.component.html',
  styleUrls: ['./assets-management.component.scss'],
})
export class AssetsManagementComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(AppConfigurationState.currentAppConfigName)
  currentAppConfigName$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigLogo)
  currentAppConfigLogo$: Observable<string>;

  @Select(AppConfigurationState.currentAppIdPlan)
  currentAppIdPlan$: Observable<number>;

  @Select(StepperState.showSteps)
  showSteps$: Observable<boolean>;

  @Select(AppConfigurationState.appConfigAssetList)
  appConfigAssetList$: Observable<AppConfigAssets>;

  @Select(AppConfigurationState.isLoadingAssets)
  isLoadingAssets$: Observable<boolean>;

  @Select(AppConfigurationState.availableOnAndroidTV)
  availableOnAndroidTV$: Observable<boolean>;

  @Select(AppConfigurationState.availableOnAppleTV)
  availableOnAppleTV$: Observable<boolean>;

  nextStepIsDisabled = false;
  appName = new FormControl(null);
  smartphoneUrl$: Observable<string>;
  smartphoneLongUrl$: Observable<string>;
  splashscreenTv$: Observable<string>;
  topShelfTv$: Observable<string>;
  iconTv$: Observable<string>;
  mockImageUrls = {
    splashscreen: '/assets/smartphone.png',
    splashscreenLarge: '/assets/smartphone-long.png',
    splashscreenTv: '/assets/tv.png',
    iconTv: '/assets/icon-tv.png',
    topShelfTv: '/assets/topshelf-tv.png',
  };
  planId: number;

  requiredImagesPerPlanId: { [key: number]: string[] } = {
    4: ['splashscreen'],
    5: ['splashscreen'],
    6: ['splashscreen', 'splashscreenTv', 'iconTv', 'topShelfTv'],
  };

  imageTranslatedNames: { [key: string]: string } = {
    splashscreen: marker('assets.smartphone'),
    splashscreenTv: marker('assets.tv'),
    iconTv: marker('assets.icon-tv'),
    topShelfTv: marker('assets.topshelf-tv-short'),
  };

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly unsavedChangesService: UnsavedChangesService,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.store.dispatch(new SkipToStep(2));

    // fetch config
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const appId = params['appId'];
        if (appId) {
          this.store.dispatch(new FetchAppConfigRequest(appId));
          this.store.dispatch(new SetCurrentApp(Number(appId)));
        }
      }),
    );

    // set app name
    this.subscriptions.add(
      this.currentAppConfigName$
        .pipe(filter(config => !!config))
        .subscribe(name => this.appName.setValue(name)),
    );

    this.smartphoneUrl$ = this.currentAppConfig$.pipe(
      filter(config => !!config),

      map(config => this.constructUrl(config?.splashscreen)),
    );

    this.smartphoneLongUrl$ = this.currentAppConfig$.pipe(
      filter(config => !!config),

      map(config => this.constructUrl(config?.splashscreenLarge)),
    );

    this.splashscreenTv$ = this.currentAppConfig$.pipe(
      filter(config => !!config),

      map(config => this.constructUrl(config?.splashscreenTv)),
    );

    this.iconTv$ = this.currentAppConfig$.pipe(
      filter(config => !!config),

      map(config => this.constructUrl(config?.iconTv)),
    );

    this.topShelfTv$ = this.currentAppConfig$.pipe(
      filter(config => !!config),

      map(config => this.constructUrl(config?.topShelfTv)),
    );

    // react to app name changes
    this.subscriptions.add(
      this.appName.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new AddConfigAsset('name', value));
        }),
    );

    // check for missing assets and update the tooltip message
    this.subscriptions.add(
      combineLatest([
        this.currentAppIdPlan$,
        this.appConfigAssetList$,
        this.currentAppConfig$,
      ]).subscribe(([planId, assets, config]) => {
        if (!config.splashscreen) {
          this.checkForMissingAssetsAndUpdateTooltip(planId, assets);
        }
      }),
    );

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'PageSimulatorAssets',
        component: 'Page',
      }),
    );
    this.store.dispatch(new LogEvent('Page Viewed'));

    this.subscriptions.add(
      this.currentAppIdPlan$.subscribe(id => {
        this.planId = id;
      }),
    );
  }

  private constructUrl(splashscreen: string | undefined): string | null {
    return splashscreen
      ? `https://${environment.urls.RADIOKING_DOMAIN}/upload/applications/${splashscreen}`
      : null;
  }

  onImageSelected(event: any, name: string) {
    this.store.dispatch(new AddConfigAsset(name, event.file));
  }

  removeImage(fileName: string) {
    this.store.dispatch(new UpdateAppConfig([fileName], null));
  }

  postAssets = (): void => {
    if (this.planId !== 6) {
      // prevent scrolling when the dialog is opened
      document.body.classList.add('disable-scroll');

      const dialogRef = this.dialog.open(PublicationAddonDialogComponent, {});
      dialogRef.afterClosed().subscribe(result => {
        if (result === undefined) {
          document.body.classList.remove('disable-scroll');

          return;
        } else {
          if (result === true) {
            this.store.dispatch(new TogglePayingPublicationAddon(true));
          } else {
            this.store.dispatch(new TogglePayingPublicationAddon(false));
          }
          this.store.dispatch(new PostAssetsRequest());
        }
      });
    } else {
      this.store.dispatch(new PostAssetsRequest());
    }
  };

  private checkForMissingAssetsAndUpdateTooltip(planId: number, assets: AppConfigAssets) {
    const requiredImages = this.requiredImagesPerPlanId[planId];
    let missingImages = [];

    if (assets) {
      missingImages = requiredImages.filter((image: string) => !assets[image]);
    } else {
      missingImages = requiredImages;
    }

    if (missingImages.length > 0) {
      const missingImageNames = missingImages.map((image: string) =>
        this.translate.instant(this.imageTranslatedNames[image]),
      );

      const tooltipMessageKey = 'assets.missing-images';
      const tooltipMessage = this.translate.instant(tooltipMessageKey);
      const fullToolTipMessage = `${tooltipMessage} ${missingImageNames.join(', ')}`;

      this.store.dispatch(new UpdateTooltipMessage(fullToolTipMessage));
      this.nextStepIsDisabled = true;
    } else {
      this.nextStepIsDisabled = false;
      this.store.dispatch(new UpdateTooltipMessage(null));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
