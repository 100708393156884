import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetShowAppConfigMobileWarning,
  SetShowAppManagerMobileWarning,
} from './responsive.actions';

export class ResponsiveStateModel {
  showAppConfigMobileWarning: boolean;
  showAppManagerMobileWarning: boolean;
}

@State<ResponsiveStateModel>({
  name: 'responsive',
  defaults: {
    showAppConfigMobileWarning: true,
    showAppManagerMobileWarning: true,
  },
})
@Injectable()
export class ResponsiveState {
  @Selector()
  static showAppConfigMobileWarning(state: ResponsiveStateModel): boolean {
    const isMobileScreen = screen.width <= 768;

    return isMobileScreen && state.showAppConfigMobileWarning;
  }

  @Selector()
  static showAppManagerMobileWarning(state: ResponsiveStateModel): boolean {
    const isMobileScreen = screen.width <= 768;

    return isMobileScreen && state.showAppManagerMobileWarning;
  }


  @Action(SetShowAppConfigMobileWarning)
  setShowAppConfigMobileWarning(
    ctx: StateContext<ResponsiveStateModel>,
    { showAppConfigMobileWarning }: SetShowAppConfigMobileWarning,
  ) {
    ctx.patchState({
      showAppConfigMobileWarning,
    });
  }

  @Action(SetShowAppManagerMobileWarning)
  setShowAppManagerMobileWarning(
    ctx: StateContext<ResponsiveStateModel>,
    { showAppManagerMobileWarning }: SetShowAppManagerMobileWarning,
  ) {
    ctx.patchState({
      showAppManagerMobileWarning,
    });
  }
}
