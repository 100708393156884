const tag = '[Responsive]';

export class SetShowAppConfigMobileWarning {
  static readonly type = `${tag} set show mobile warning`;
  constructor(public showAppConfigMobileWarning: boolean) {}
}

export class SetShowAppManagerMobileWarning {
  static readonly type = `${tag} set show app manager mobile warning`;
  constructor(public showAppManagerMobileWarning: boolean) {}
}
