<h1>{{ planName }}</h1>
<div class="price-line">
  <div class="price">
    <span class="price-value">{{ getPricing() }}</span>
    @if (billing === 'Monthly') {
      <span class="per-month" translate>plan.per-month</span>
    } @else {
      <span class="per-month" translate>plan.per-year</span>
    }
  </div>
</div>
<div class="monthly" *ngIf="billing === 'Annually'">
  <div class="line-through text-center text-lg">
    {{ getfullAnnualPricing() }}<span class="per-month" translate>plan.per-year</span>
  </div>
</div>
<div class="upgrade-button">
  <rk-button
    (buttonClick)="choosePlan()"
    [class]="recommended ? 'primary' : 'secondary'"
    [text]="'plan.choose' | translate"
    [fullWidth]="true"
  ></rk-button>
</div>
<div class="offer-details mt-5">
  <div class="limit" *ngIf="plan === 4">
    <div class="value" [innerHTML]="'plan.android' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 4">
    <div class="value" [innerHTML]="'plan.android-and-ios' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.auto' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.tv' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.apple-watch' | translate"></div>
  </div>
  <div class="limit">
    <div class="value" [innerHTML]="'plan.customizable' | translate"></div>
  </div>
  <div class="limit">
    <div class="value" [innerHTML]="'plan.unlimited-tabs' | translate"></div>
  </div>
  <div class="limit">
    <div class="value" [innerHTML]="'plan.unlimited-downloads' | translate"></div>
  </div>
  <div class="limit">
    <div class="value" [innerHTML]="'plan.multi-stream' | translate"></div>
  </div>
  <div class="limit">
    <div class="value" [innerHTML]="'plan.multi-language' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan < 6">
    <div class="value" [innerHTML]="'plan.user-published' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan === 6">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.rk-published' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.publication-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan > 4">
    <div
      class="value"
      [innerHTML]="'plan.push' | translate: { number: pushNotifications }"
    ></div>
  </div>
  <div class="limit" *ngIf="plan > 4">
    <div class="value" [innerHTML]="'plan.white-label' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.auto' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.carplay-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.tv' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.apple-watch' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.monetization' | translate"></div>
  </div>
</div>
