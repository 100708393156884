import {
  AlarmClockTabModel,
  AudioDedicationTabModel,
  DedicationTabModel,
  HtmlTabModel,
  LiveFeedTabModel,
  RadioTabModel,
  RssTabModel,
  StreamModel,
  WebsiteTabModel,
} from './app-tabs.model';

// this model is common to the React Native App and the API
export interface AppConfig {
  admobId: string | null;
  admobAppId: string | null;
  availableOnAndroid: boolean;
  availableOnAndroidTV: boolean;
  availableOnAndroidAuto: boolean;
  availableOnIphone: boolean;
  availableOnAppleWatch: boolean;
  availableOnAppleTV: boolean;
  availableOnCarPlay: boolean;
  androidNotificationAvailable: boolean;
  bundleId: string;
  flurryApiKey: string | 'null';
  idapplications: number;
  // WHMCS ID - different from auth User ID
  idutilisateurs: number;
  idplan: number;
  newidplan: number;
  pubOption: 'YES' | 'NO';
  pubProvider: 'admob' | 'aucun' | null;
  statsOption: 'YES' | 'NO';
  statsProvider: 'flurry' | 'aucun' | null;
  themeGeneral: string;
  urlAppStore: string | null;
  urlGooglePlay: string | null;
  urlSiteRadio: string | null;
  lang: string;
  lastmod: string;
  marqueBlancheOption: 'YES' | 'NO';
  menuBackgroundColor: string;
  menuColor: string;
  monetizationAvailable: boolean;
  nomRadio: string;
  images: ImagesModel[];
  onglets: AppConfigTabs;
}

// this extension of the model is only used to exchange data with the API
// it is not used by the React Native app
export interface AppConfigApi extends AppConfig {
  nom: string;
  logo: string;
  splashscreen: string | null;
  splashscreenLarge: string | null;
  iconTv: string | null;
  splashscreenTv: string | null;
  topShelfTv: string | null;
  streams: StreamApi[];
  paye: 'YES' | 'NO';
  status: string;
}

// this stream model is only used to exchange data with the API
export interface StreamApi {
  idflux?: number;
  idapplications?: number;
  idonglets?: number;
  typeFlux: string;
  manager: string | null;
  identifiant: string | null;
  urlFlux: string;
  nomFlux: string;
  mountPoint: string | null;
  radiouid: string | null;
  urlTitrage: string | null;
  typeTitrage: string | null;
  logofluxappfield: string | null;
  ordre: number;
  fondTitrage: string | null;
  pochette: string | null;
  idradio: number | null;
  urlFluxBD: string | null;
  urlFluxHD: string | null;
}

export type AppConfigTabs = [
  RadioTabModel,
  AudioDedicationTabModel,
  AlarmClockTabModel,
  HtmlTabModel,
  DedicationTabModel,
  LiveFeedTabModel,
  RssTabModel,
  WebsiteTabModel,
];

export interface ImagesModel {
  key: string;
  nom: string;
  url: string;
}

export function transformStreamApiToStreamModel(
  streamApi: StreamApi,
  index: number,
): StreamModel {
  let urlFlux = streamApi.urlFlux;

  // If the stream is of type 'icecast', ensure the URL ends with a slash before adding the mount point
  if (streamApi.typeFlux === 'icecast') {
    urlFlux = ensureUrlEndsWithSlash(streamApi.urlFlux) + streamApi.mountPoint;
  }

  return {
    id: streamApi.idflux,
    logoappfield: streamApi.logofluxappfield ? `logoflux${index}appfield` : null,
    nomFlux: streamApi.nomFlux,
    typeTitrage: streamApi.typeTitrage,
    urlFlux,
    urlFluxBD: streamApi.urlFluxBD,
    urlFluxHD: streamApi.urlFluxHD,
  };
}

export function updateRadioTabFluxFromStreamApis(appConfig: AppConfigApi): AppConfigApi {
  // clone the appConfig to not mutate the original
  const clonedConfig = JSON.parse(JSON.stringify(appConfig));

  const streamModels: StreamModel[] = clonedConfig?.streams.map(
    (streamApi: StreamApi, i: number) => transformStreamApiToStreamModel(streamApi, i),
  );

  if (clonedConfig?.onglets && clonedConfig?.onglets.length > 0) {
    const updatedRadioTab = { ...clonedConfig.onglets[0], flux: streamModels };
    clonedConfig.onglets[0] = updatedRadioTab;
  }

  return clonedConfig;
}

function ensureUrlEndsWithSlash(url: string) {
  if (!url.endsWith('/')) {
    return `${url}/`;
  }

  return url;
}
