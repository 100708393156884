<div
  class="flex flex-col items-center w-full mt-13"
  *ngIf="monetizationAvailable$ | async"
>
  <div class="w-[500px]">
    <div class="flex justify-between items-center">
      <div class="font-bold">
        {{ 'advertising.title' | translate }}
      </div>
      <rk-switch
        [isChecked]="showAdProvider"
        (toggle)="onAdProviderToggle($event)"
      ></rk-switch>
    </div>
    <div class="mt-2">
      {{ 'advertising.description' | translate }}
    </div>
    @if (showAdProvider) {
      <div class="flex w-full gap-2 mt-6 mb-2">
        <rk-form-field class="flex-1">
          <div class="opacity-70 mb-1">{{ 'advertising.app-id' | translate }}</div>
          <input type="text" [formControl]="admobAppId" />
        </rk-form-field>
        <rk-form-field class="flex-1">
          <div class="opacity-70 mb-1">{{ 'advertising.block-id' | translate }}</div>
          <input type="text" [formControl]="admobBlockId" />
        </rk-form-field>
      </div>
      <rk-infobox>
        {{ 'advertising.infobox' | translate }}
        <a href="https://apps.admob.com" class="font-bold" target="_blank"
          >apps.admob.com.
        </a>
        <br /><br />
        <a
          [href]="'advertising.infobox-link' | translate"
          class="font-bold"
          target="_blank"
          >{{ 'advertising.infobox-cta' | translate }}
        </a>
      </rk-infobox>
    }
  </div>
</div>
