import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { whmcsCurrency } from '@app/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { UpdatePlanRequest } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { planNames } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

export interface Prices {
  [key: string]: {
    [key: string]: {
      [key: number]: string;
    };
  };
}

const prices: Prices = {
  Monthly: {
    EUR: {
      4: '29€',
      5: '69€',
      6: '99€',
    },
    GBP: {
      4: '£29',
      5: '£69',
      6: '£99',
    },
    USD: {
      4: '$29',
      5: '$69',
      6: '$99',
    },
  },
  Annually: {
    EUR: {
      4: '290€',
      5: '690€',
      6: '990€',
    },
    GBP: {
      4: '£290',
      5: '£690',
      6: '£990',
    },
    USD: {
      4: '$290',
      5: '$690',
      6: '$990',
    },
  },
  AnnuallyFull: {
    EUR: {
      4: '348€',
      5: '828€',
      6: '1188€',
    },
    GBP: {
      4: '£348',
      5: '£828',
      6: '£99',
    },
    USD: {
      4: '$348',
      5: '$828',
      6: '$1188',
    },
  },
};

@Component({
  selector: 'rk-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferComponent {
  @Input() plan: number;

  @Input() planName: string;

  @Input() pushNotifications: string;

  @Input() streamCount: number;

  @Input() currency: whmcsCurrency;

  @Input() monthlyPricing: string;

  @Input() annualPricing: string;

  @Input() billing: string;

  @HostBinding('class.recommended')
  @Input()
  recommended = false;

  @Input() focused$: Observable<void>;

  @Output() readonly clickButton = new EventEmitter();

  constructor(private readonly store: Store) {}

  choosePlan() {
    this.store.dispatch(new UpdatePlanRequest(this.plan, this.billing));
    this.clickButton.emit();
    const planName = planNames.find(plan => plan.id === this.plan).name;

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonPricingStart' + planName,
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  getPricing() {
    if (this.currency) {
      return prices[this.billing][this.currency][this.plan];
    }

    return null;
  }

  getfullAnnualPricing() {
    return prices['AnnuallyFull'][this.currency][this.plan];
  }
}
