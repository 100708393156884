import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from "../ui/ui.module";
import { AuthGuard } from './auth/auth.guard';
import { SAVED_TO_LOCAL_STORAGE_NGXS } from './constants/constants';
import { BearerInterceptor } from './http/bearer.interceptor';
import { StorageEngineNgxs } from './StorageEngineNgxs';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MobileAccessWarningComponent } from './components/mobile-access-warning/mobile-access-warning.component';
import { PaidGuard } from './auth/paid.guard';
import { ActiveGuard } from './auth/active.guard';
import { DraftGuard } from './auth/draft.guard';
import { UpgradeOfferComponent } from './components/upgrade-offer/upgrade-offer.component';

@NgModule({
  declarations: [
    UserMenuComponent,
    MobileAccessWarningComponent,
    UpgradeOfferComponent,
  ],
  imports: [
    FontAwesomeModule,
    TranslateModule,
    CommonModule,
    NgxsStoragePluginModule.forRoot({
        key: SAVED_TO_LOCAL_STORAGE_NGXS,
    }),
    UiModule
],
  providers: [
    AuthGuard,
    PaidGuard,
    DraftGuard,
    ActiveGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: StorageEngineNgxs,
    },
  ],
  exports: [
    UserMenuComponent,
    MobileAccessWarningComponent,
    UpgradeOfferComponent,
  ]
})
export class CoreModule { }
