import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CoreModule } from '@app/core/core.module';
import { ResponsiveState } from '@app/core/states/responsive.state';
import { UiModule } from '@app/ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NotificationDashboardComponent } from './components/notification-dashboard/notification-dashboard.component';
import { NotificationFormAlertComponent } from './components/notification-form-alert/notification-form-alert.component';
import { NotificationFormComponent } from './components/notification-form/notification-form.component';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';
import { NotificationTableComponent } from './components/notification-table/notification-table.component';
import { NotificationsState } from './states/notifications.state';

@NgModule({
  declarations: [
    NotificationTableComponent,
    NotificationDashboardComponent,
    NotificationFormComponent,
    NotificationSidebarComponent,
    NotificationFormAlertComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    UiModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    TranslateModule,
    NgxsModule.forFeature([NotificationsState, ResponsiveState]),
    ReactiveFormsModule,
  ],
  exports: [NotificationTableComponent, NotificationSidebarComponent],
})
export class NotificationsModule {}
