import { Component } from '@angular/core';

@Component({
  selector: 'rk-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrl: './help-icon.component.scss'
})
export class HelpIconComponent {

}
