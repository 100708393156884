<div class="bg absolute w-full overflow-hidden pointer-events-none">
  <img
    src="/assets/wave-plan.svg"
    class="absolute max-w-none min-w-full max-h-full object-bottom object-cover"
  />
</div>
<div class="h-full flex flex-col overflow-scroll pb-10 min-w-[1000px] px-6">
  <div class="text-white text-center z-10 mt-10">
    <h1 class="mt-16 md:mt-12 mb-0" translate>plan.pick-title</h1>
    <span translate>plan.subtitle</span>
  </div>
  <mat-button-toggle-group
    class="mt-9"
    (change)="changeBilling($event)"
    [class.monthly]="billing === 'Monthly'"
  >
    <mat-button-toggle [disableRipple]="true" value="Monthly" checked>{{
      'plan.monthly' | translate
    }}</mat-button-toggle>
    <mat-button-toggle [disableRipple]="true" value="Annually"
      >{{ 'plan.annual' | translate }}
      <span class="tag text-sm h-4.5 rounded-full -top-0.5 ml-4"
        >{{ 'plan.two-months-free' | translate }}</span
      ></mat-button-toggle
    >
  </mat-button-toggle-group>
  <div class="plans  self-center justify-around flex">
    <div class="mt-[51px]">
      <rk-offer
        [plan]="4"
        [billing]="billing"
        [planName]="'BASIC'"
        [currency]="currency$ | async"
      ></rk-offer>
    </div>
    <div>
      <div class="offer-header relative bg-black text-white" translate>
        plan.recommended
      </div>
      <rk-offer
        [plan]="5"
        [pushNotifications]="'50 000'"
        [billing]="billing"
        [planName]="'SMART'"
        [recommended]="true"
        [currency]="currency$ | async"
      ></rk-offer>
    </div>
    <div class="mt-[51px]">
      <rk-offer
        [plan]="6"
        [pushNotifications]="'100 000'"
        [billing]="billing"
        [planName]="'PREMIUM'"
        [currency]="currency$ | async"
      ></rk-offer>
    </div>
  </div>
</div>
