import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { AddConfigAsset } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
} from 'rxjs';

@Component({
  selector: 'rk-monetization',
  standalone: false,
  templateUrl: './monetization.component.html',
  styleUrl: './monetization.component.scss',
})
export class MonetizationComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(AppConfigurationState.monetizationAvailable)
  monetizationAvailable$: Observable<boolean>;

  showAdProvider = false;
  admobAppId = new FormControl(null);
  admobBlockId = new FormControl(null);
  private readonly subscriptions = new Subscription();

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    // set initial config
    this.subscriptions.add(
      this.currentAppConfig$.pipe(filter(config => !!config)).subscribe(config => {
        this.admobAppId.setValue(config.admobAppId);
        this.admobBlockId.setValue(config.admobId);
        this.showAdProvider = config.pubOption === 'YES';
        if (this.showAdProvider) {
          this.store.dispatch(new AddConfigAsset('pubOption', '1'));
          this.store.dispatch(new AddConfigAsset('admobAppId', config.admobAppId));
          this.store.dispatch(new AddConfigAsset('admobId', config.admobId));
        }
      }),
    );

    // react to admob app Id changes
    this.subscriptions.add(
      this.admobAppId.valueChanges
        .pipe(skip(1), debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new AddConfigAsset('admobAppId', value));
        }),
    );

    // react to admob block Id changes
    this.subscriptions.add(
      this.admobBlockId.valueChanges
        .pipe(skip(1), debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          this.store.dispatch(new AddConfigAsset('admobId', value));
        }),
    );
  }

  onAdProviderToggle(showAdProvider: boolean) {
    this.showAdProvider = showAdProvider;
    if (showAdProvider) {
      this.store.dispatch(new AddConfigAsset('pubOption', '1'));
      this.store.dispatch(new AddConfigAsset('admobAppId', this.admobAppId.value));
      this.store.dispatch(new AddConfigAsset('admobId', this.admobBlockId.value));
    } else {
      this.store.dispatch(new AddConfigAsset('pubOption', '0'));
    }
    const status = showAdProvider ? 'ON' : 'OFF';

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'SwitchAdsProvider',
        component: 'Switch',
        status : 'Status ' + status,
      }),
    );
    this.store.dispatch(new LogEvent('Switch Clicked'));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
