<div class="h-full flex justify-center items-center flex-col p-8">
  <img class="mb-8 max-w-[400px]" [src]="'upgrade-offer.image' | translate" />
  <h1 class="text-[24px] text-center font-bold max-w-[600px]">{{ "upgrade-offer.title" | translate }}</h1>
  <p class="text-[16px] opacity-70 text-center max-w-[600px]">{{ "upgrade-offer.description" | translate }}</p>
  <rk-button
    class="mt-10"
    [text]="'upgrade-offer.button' | translate"
    (buttonClick)="upgradeOffer()">
  </rk-button>
</div>
