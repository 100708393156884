import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import {
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NgxMatMomentModule,
} from '@angular-material-components/moment-adapter';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ColorPickerModule } from 'ngx-color-picker';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './components/alert/alert.service';
import { ApiErrorAlertComponent } from './components/api-error-alert/api-error-alert.component';
import { AppConfigurationSubmenuComponent } from './components/app-configuration-submenu/app-configuration-submenu.component';
import { AppNameInputComponent } from './components/app-name-input/app-name-input.component';
import { AutocompleteInputComponent } from './components/auto-complete-input/autocomplete-input.component';
import { ButtonComponent } from './components/button/button.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { IconSearchComponent } from './components/icon-search/icon-search.component';
import { ImageSizeGuideComponent } from './components/image-size-guide/image-size-guide.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { InfoboxComponent } from './components/infobox/infobox.component';
import { MenuComponent } from './components/menu/menu.component';
import { PageConfigurationContainerComponent } from './components/page-configuration-container/page-configuration-container.component';
import { PageDesignEditorComponent } from './components/page-design-editor/page-design-editor.component';
import { PageIconAndNameEditorComponent } from './components/page-icon-and-name-editor/page-icon-and-name-editor.component';
import { PageNameErrorComponent } from './components/page-name-error/page-name-error.component';
import { SettingContainerComponent } from './components/setting-container/setting-container.component';
import { SettingDividerComponent } from './components/setting-divider/setting-divider.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { UpdateAvailableComponent } from './components/update-available/update-available.component';
import { HelpIconComponent } from './components/help-icon/help-icon.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'llll',
  },
  display: {
    dateInput: 'llll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    FormFieldComponent,
    DatePickerComponent,
    UpdateAvailableComponent,
    AutocompleteInputComponent,
    ConfirmationDialogComponent,
    ImageUploadComponent,
    SettingContainerComponent,
    SettingDividerComponent,
    ColorSelectorComponent,
    IconSearchComponent,
    AppConfigurationSubmenuComponent,
    ImageSizeGuideComponent,
    PageIconAndNameEditorComponent,
    PageNameErrorComponent,
    ApiErrorAlertComponent,
    PageDesignEditorComponent,
    PageConfigurationContainerComponent,
    UnsavedChangesDialogComponent,
    AppNameInputComponent,
    ButtonComponent,
    DropdownComponent,
    SwitchComponent,
    MenuComponent,
    AlertComponent,
    TooltipComponent,
    TooltipDirective,
    InfoboxComponent,
    HelpIconComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatInputModule,
    ColorPickerModule,
    ScrollingModule,
    MatExpansionModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgCircleProgressModule.forRoot({}),
    MatProgressSpinnerModule,
    RouterModule,
  ],
  exports: [
    AutocompleteInputComponent,
    FormFieldComponent,
    DatePickerComponent,
    UpdateAvailableComponent,
    ConfirmationDialogComponent,
    ImageUploadComponent,
    SettingContainerComponent,
    SettingDividerComponent,
    ColorSelectorComponent,
    IconSearchComponent,
    AppConfigurationSubmenuComponent,
    ImageSizeGuideComponent,
    PageIconAndNameEditorComponent,
    PageNameErrorComponent,
    ApiErrorAlertComponent,
    PageDesignEditorComponent,
    PageConfigurationContainerComponent,
    AppNameInputComponent,
    ButtonComponent,
    DropdownComponent,
    SwitchComponent,
    MenuComponent,
    AlertComponent,
    TooltipComponent,
    TooltipDirective,
    InfoboxComponent,
    HelpIconComponent,
  ],
  providers: [
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    AlertService,
  ],
})
export class UiModule {}
