import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@app/core/auth/states/auth.state';
import {
  SetShowAppConfigMobileWarning,
  SetShowAppManagerMobileWarning,
} from '@app/core/states/responsive.actions';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-mobile-access-warning',
  templateUrl: './mobile-access-warning.component.html',
  styleUrls: ['./mobile-access-warning.component.scss'],
})
export class MobileAccessWarningComponent {
  @Select(AuthState.userRKDomain)
  userRKDomain$: Observable<string>;

  @Input()
  isAppManager: boolean;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  onMobileAccessWarningCtaClick() {
    if (this.isAppManager) {
      this.store.dispatch(new SetShowAppManagerMobileWarning(false));
    } else {
      this.store.dispatch(new SetShowAppConfigMobileWarning(false));
    }
  }
}
