<div class="p-6 flex flex-col">
  <div class="mb-8">
    <h1 class="text-2xl font-bold mb-3">
      {{ 'unsaved-changes-dialog.close-confirmation-message' | translate }}
    </h1>
    <p class="opacity-70">
      {{ 'unsaved-changes-dialog.close-confirmation-message-details' | translate }}
    </p>
  </div>
  <div class="flex justify-end gap-2 md:gap-4 flex-col-reverse md:flex-row">
    <rk-button
      [text]="'unsaved-changes-dialog.cancel' | translate"
      [variant]="'secondary'"
      (buttonClick)="onDismiss()"
    ></rk-button>
    <rk-button
      [text]="'unsaved-changes-dialog.close-confirmation-button' | translate"
      (buttonClick)="onConfirm()"
    ></rk-button>
  </div>
</div>
