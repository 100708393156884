import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthState } from '@app/core/auth/states/auth.state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppsListComponent } from './core/components/apps-list/apps-list.component';
import { HeaderComponent } from './core/components/header/header.component';
import { MainMenuMobileComponent } from './core/components/main-menu-mobile/main-menu-mobile.component';
import { MainMenuComponent } from './core/components/main-menu/main-menu.component';
import { CoreModule } from './core/core.module';
import { DirectivesModule } from './core/directives/directives.module';
import { AppState } from './core/states/app.state';
import { AdminModule } from './features/admin/admin.module';
import { AppConfigurationModule } from './features/app-creation-and-configuration/app-configuration.module';
import { LoginComponent } from './features/login/login.component';
import { NotificationsModule } from './features/notifications/notifications.module';
import { StatisticsModule } from './features/statistics/statistics.module';
import { MaterialModule } from './material.module';
import { UiModule } from './ui/ui.module';
import { EventsTrackingState } from './core/states/event-tracking.state';
import { AlertService } from './ui/components/alert/alert.service';
import { ResponsiveState } from './core/states/responsive.state';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MainMenuComponent,
    MainMenuMobileComponent,
    AppsListComponent,
  ],
  imports: [
    AdminModule,
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppConfigurationModule,
    MaterialModule,
    AppRoutingModule,
    NotificationsModule,
    StatisticsModule,
    GoogleMapsModule,
    UiModule,
    CoreModule,
    FontAwesomeModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    BaseChartDirective,
    DirectivesModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    NgxsModule.forRoot([AuthState, AppState, EventsTrackingState, ResponsiveState], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AlertService,
    DatePipe,
    DecimalPipe,
    [provideCharts(withDefaultRegisterables())],
    { provide: 'googleTagManagerId', useValue: environment.gtm },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
