<div class="mobile-access-warning-container bg-[#fff2ed] flex flex-col items-center justify-between">
  <img
    class="mb-8"
    [src]="
      isAppManager
        ? '/assets/app-manager-placeholder.png'
        : '/assets/simulator-placeholder.png'
    "
  />
  <div>
    <h1 class="text-[26px] px-8 font-bold text-center mb-3">
      {{ 'mobile-access-warning.title' | translate }}
    </h1>
    <p class="text-lg opacity-70 text-center px-8">
      @if (isAppManager) {
        {{ 'mobile-access-warning.description-app-manager' | translate }}
      } @else {
        {{ 'mobile-access-warning.description' | translate }}
      }
    </p>
  </div>

  <div class="flex flex-col gap-3 px-8 pb-11 mt-6">
    <rk-button
      text="{{ 'mobile-access-warning.cta' | translate }}"
      [fullWidth]="true"
      (buttonClick)="onMobileAccessWarningCtaClick()"
    ></rk-button>
  </div>
</div>
