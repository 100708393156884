<rk-welcome-animation *ngIf="showWelcomeAnimation"></rk-welcome-animation>
<!-- desktop creation form // see mobile below -->
<div
  *ngIf="!showWelcomeAnimation"
  class="create-form-container w-full h-full justify-center items-center hidden md:flex"
  [@fadeInOut]
>
  <div class="h-full w-full content-container relative">
    <!-- form -->
    <div class="flex flex-col justify-center form-container">
      <div class="relative">
        <div class="font-bold text-[40px] mb-2">
          {{ 'create-app-form.choose-name' | translate }}
        </div>
        <!-- name input and error messages -->
        <rk-app-name-input
          [appName]="appName"
          (appNameChange)="onAppNameChange($event)"
          (errorCountChange)="onErrorCountChange($event)"
          (nameIsAvailable)="nameIsAvailable = $event"
          (nameHasNoErrors)="nameHasNoErrors = $event"
        ></rk-app-name-input>
        <!-- Button -->
        <!-- vertical offset if number of errors is > 2 -->
        <div
          class="absolute left-0 top-[153px] create-app-button"
          [style.transform]="
            errorCount > 1 ? 'translateY(' + errorCount * 12 + 'px)' : 'translateY(0px)'
          "
        >
          <rk-button
            [text]="'create-app-form.start' | translate"
            (buttonClick)="onConfirmAppName()"
            [size]="'large'"
            [disabled]="formIsInvalid || (isLoading$ | async)"
            [isLoading]="isLoading$ | async"
          ></rk-button>
        </div>
      </div>
    </div>
    <div class="background-image"></div>
  </div>
</div>
<!-- mobile creation form -->
<div [@fadeInOut] class="create-form-mobile-shell md:hidden">
  <div class="h-[45px] bg-white flex items-center p-2">
    <img class="max-h-full" src="/assets/rk-logo.png" />
  </div>
  <div
    class="h-full bg-[#fff2ed] flex flex-col justify-between create-form-mobile-container"
  >
    <img src="/assets/create-app-responsive.png" class=" object-cover create-app-picture-responsive" alt="" />

    <div class="mx-6 mb-6">
      <div class="mb-6 text-lg opacity-70">
        {{ 'create-app-form.choose-name-explained' | translate }}
      </div>
      <div class="font-bold text-2xl mb-4">
        {{ 'create-app-form.choose-name' | translate }}
      </div>
      <!-- name input and error messages -->
      <rk-app-name-input
        [appName]="appName"
        (appNameChange)="onAppNameChange($event)"
        (errorCountChange)="onErrorCountChange($event)"
        (nameIsAvailable)="nameIsAvailable = $event"
        (nameHasNoErrors)="nameHasNoErrors = $event"
      ></rk-app-name-input>
      <!-- Button -->
      <div class="mt-7">
        <rk-button
          [text]="'create-app-form.start' | translate"
          (buttonClick)="onConfirmAppName()"
          [size]="'large'"
          [disabled]="formIsInvalid || (isLoading$ | async)"
          [isLoading]="isLoading$ | async"
          [fullWidth]="true"
        ></rk-button>
      </div>
    </div>
  </div>
</div>
