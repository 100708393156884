import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CoreModule } from '@app/core/core.module';
import { ResponsiveState } from '@app/core/states/responsive.state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { BaseChartDirective } from 'ng2-charts';
import { NotificationsModule } from '../notifications/notifications.module';
import { DataChartComponent } from './components/data-chart/data-chart.component';
import { StatisticsDashboardComponent } from './components/statistics-dashboard/statistics-dashboard.component';
import { StatisticsState } from './states/statistics.state';

@NgModule({
  declarations: [StatisticsDashboardComponent, DataChartComponent],
  imports: [
    FontAwesomeModule,
    BaseChartDirective,
    CommonModule,
    CoreModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NotificationsModule,
    TranslateModule,
    NgxsModule.forFeature([StatisticsState, ResponsiveState]),
  ],
  providers: [DatePipe, DecimalPipe],
})
export class StatisticsModule {}
