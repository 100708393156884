import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { whmcsCurrency } from '@app/core';
import { AuthState } from '@app/core/auth/states/auth.state';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select } from '@ngxs/store';
import { combineLatest, filter, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-publication-addon-dialog',
  templateUrl: './publication-addon-dialog.component.html',
  styleUrl: './publication-addon-dialog.component.scss',
})
export class PublicationAddonDialogComponent implements OnInit, OnDestroy {
  @Select(AuthState.userCurrency)
  currency$: Observable<whmcsCurrency>;

  @Select(AppConfigurationState.currentAppIdPlan)
  currentAppIdPlan$: Observable<number>;

  prices: { [key: string]: { [key: number]: string } } = {
    EUR: {
      4: '50€',
      5: '100€',
      6: '100€',
    },
    GBP: {
      4: '£50',
      5: '£100',
      6: '£100',
    },
    USD: {
      4: '$50',
      5: '$100',
      6: '$100',
    },
  };

  addOnPrice: string;

  constructor(
    private readonly dialogRef: MatDialogRef<PublicationAddonDialogComponent>,
  ) {}

  private readonly subscriptions = new Subscription();

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([this.currentAppIdPlan$, this.currency$])
        .pipe(filter(([plan, currency]) => !!plan && !!currency))
        .subscribe(([plan, currency]) => {
          this.addOnPrice = this.prices[currency][plan];
        }),
    );
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
