<div class="h-full flex flex-col bg-white">
  <rk-stepper
    [customNextStep]="postAssets"
    [customNextStepText]="'assets.next-step' | translate"
    [nextStepIsDisabled]="(showSteps$ | async) && nextStepIsDisabled"
  ></rk-stepper>
  <div class="h-full overflow-scroll">
    @if ((showSteps$ | async) === false) {
      <!-- app name -->
      <div class="w-full flex justify-center mt-13 relative">
        <div class="w-[500px]">
          <!-- name input -->
          <label class="font-bold">{{ 'assets.app-name' | translate }}</label>
          <rk-app-name-input
            [appName]="appName.value"
            (appNameChange)="appName.setValue($event)"
          ></rk-app-name-input>
        </div>
      </div>
    }
    @if (showSteps$ | async) {
      <!-- almost finished message -->
      <div class="font-bold text-4xl text-center mt-13">
        {{ 'assets.almost-finished' | translate }}
      </div>
    }
    <rk-monetization></rk-monetization>
    <div class="w-full flex flex-col items-center">
      <h1 class="w-full text-center text-2xl mt-13 font-bold">
        {{ 'assets.title' | translate }}
      </h1>
      <!-- prettier-ignore -->
      <p class="opacity-70 whitespace-pre-wrap">{{ 'assets.description' | translate }}</p>
      <div class="grid grid-cols-2 mt-9 max-w-[1200px] px-8">
        <!-- logo -->
        <div class="left-column">
          <div class="asset-upload-input bottom-line">
            <rk-image-upload
              [technicalRequirementsText]="'assets.constraints-icon-smartphone' | translate"
              (fileSelected)="onImageSelected($event, 'logo')"
              [fetchedImageUrl]="currentAppConfigLogo$ | async"
              (imageRemoved)="removeImage('logo')"
              [deleteButton]="false"
              [width]="'200px'"
              [aspectRatio]="'1/1'"
              [title]="'assets.logo' | translate"
            ></rk-image-upload>
          </div>
        </div>
        <!-- splashscreen -->
        <div class="right-column">
          <div class="asset-upload-input bottom-line">
            <rk-image-upload
              (fileSelected)="onImageSelected($event, 'splashscreen')"
              [width]="'200px'"
              [aspectRatio]="'1242/2208'"
              [containerAspectRatio]="'1/1'"
              [technicalRequirementsText]="'assets.constraints-smartphone' | translate"
              [fetchedImageUrl]="smartphoneUrl$ | async"
              (imageRemoved)="removeImage('splashscreen')"
              [deleteButton]="false"
              [mockImageUrl]="mockImageUrls.splashscreen"
              [title]="'assets.smartphone' | translate"
              [description]="'assets.smartphone-description' | translate"
            ></rk-image-upload>
          </div>
        </div>
        <!-- splashscreenLarge -->

        <div class="left-column">
          <div class="asset-upload-input h-[254px] bottom-line">
            <rk-image-upload
              (fileSelected)="onImageSelected($event, 'splashscreenLarge')"
              [width]="'200px'"
              [aspectRatio]="'1242/2688'"
              [containerAspectRatio]="'1/1'"
              [technicalRequirementsText]="
                'assets.constraints-smartphone-long' | translate
              "
              [fetchedImageUrl]="smartphoneLongUrl$ | async"
              (imageRemoved)="removeImage('splashscreenLarge')"
              [deleteButton]="false"
              [mockImageUrl]="mockImageUrls.splashscreenLarge"
              [title]="'assets.smartphone-long' | translate"
              [description]="'assets.smartphone-long-description' | translate"
            ></rk-image-upload>
          </div>
        </div>
        <!-- splashscreenTv -->
        <div class="right-column">
          <div
            class="asset-upload-input h-[254px] bottom-line"
            *ngIf="availableOnAndroidTV$ | async"
          >
            <rk-image-upload
              (fileSelected)="onImageSelected($event, 'splashscreenTv')"
              [width]="'200px'"
              [aspectRatio]="'3840/2160'"
              [containerAspectRatio]="'1/1'"
              [horizontal]="true"
              [technicalRequirementsText]="'assets.constraints-tv' | translate"
              [fetchedImageUrl]="splashscreenTv$ | async"
              (imageRemoved)="removeImage('splashscreenTv')"
              [deleteButton]="false"
              [mockImageUrl]="mockImageUrls.splashscreenTv"
              [title]="'assets.tv' | translate"
              [description]="'assets.tv-description' | translate"
            ></rk-image-upload>
          </div>
        </div>
        <!-- iconTv -->
        <div class="left-column" *ngIf="availableOnAndroidTV$ | async">
          <div class="asset-upload-input">
            <rk-image-upload
              (fileSelected)="onImageSelected($event, 'iconTv')"
              [width]="'200px'"
              [aspectRatio]="'1280/768'"
              [containerAspectRatio]="'1/1'"
              [horizontal]="true"
              [technicalRequirementsText]="'assets.constraints-icon-tv' | translate"
              [fetchedImageUrl]="iconTv$ | async"
              (imageRemoved)="removeImage('iconTv')"
              [deleteButton]="false"
              [mockImageUrl]="mockImageUrls.iconTv"
              [title]="'assets.icon-tv' | translate"
              [description]="'assets.icon-tv-description' | translate"
            ></rk-image-upload>
          </div>
        </div>
        <!-- topShelfTv -->
        <div class="right-column">
          <div class="asset-upload-input" *ngIf="availableOnAppleTV$ | async">
            <rk-image-upload
              class="w-1/2"
              (fileSelected)="onImageSelected($event, 'topShelfTv')"
              [width]="'200px'"
              [aspectRatio]="'4640/1440'"
              [containerAspectRatio]="'1/1'"
              [horizontal]="true"
              [technicalRequirementsText]="'assets.constraints-topshelf-tv' | translate"
              [fetchedImageUrl]="topShelfTv$ | async"
              (imageRemoved)="removeImage('topShelfTv')"
              [deleteButton]="false"
              [mockImageUrl]="mockImageUrls.topShelfTv"
              [title]="'assets.topshelf-tv' | translate"
              [description]="'assets.topshelf-tv-description' | translate"
            ></rk-image-upload>
          </div>
        </div>
      </div>
      <div
        class="max-w-[1200px] mt-9 mb-13 tv-infobox w-full"
        *ngIf="availableOnAndroidTV$ | async"
      >
        <rk-infobox><div [innerHTML]="'assets.tv-infobox' | translate"></div></rk-infobox>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="isLoadingAssets$ | async"
  class="absolute top-[50px] flex justify-center items-center w-full h-full backdrop-blur" 
>
  <mat-progress-spinner
    mode="indeterminate"
    [strokeWidth]="7"
    [diameter]="100"
  ></mat-progress-spinner>
</div>
