<rk-page-configuration-container>
  <div content>
    <rk-setting-divider> </rk-setting-divider>
    <div class="p-6">
      <div class="font-bold mb-4">{{ 'html-config.content' | translate }}</div>
      <div class="border-2 border-[#f8f8f8] rounded">
        @if (!isHtmlView) {
          <ngx-editor-menu
            [editor]="editor"
            [toolbar]="toolbar"
            [colorPresets]="colorPresets"
            [customMenuRef]="customCodeSwitch"
          >
          </ngx-editor-menu>
        }

        @if (isHtmlView) {
          <div class="flex justify-between items-center p-4">
            <div>Source Code</div>
            <fa-icon
              (click)="toggleHtmlView()"
              [icon]="'xmark'"
              class="cursor-pointer"
            ></fa-icon>
          </div>

          <textarea
            rows="10"
            class="w-full p-2 border rounded"
            placeholder="{{ 'html-config.code-placeholder' | translate }}"
            [formControl]="htmlContent"
          ></textarea>
        } @else {
          <ngx-editor [editor]="editor" [formControl]="htmlContent"> </ngx-editor>
        }
      </div>
    </div>
  </div>
  <div design>
    <div class="p-6">
      <rk-color-selector
        [parameter]="'html-config.background' | translate"
        (colorChange)="onBackgroundColorChange($event)"
        [initialColor]="backgroundColor$ | async"
      ></rk-color-selector>
    </div>
  </div>
</rk-page-configuration-container>

<!-- custom code switch -->
<ng-template #customCodeSwitch>
  <div class="NgxEditor__Seperator"></div>
  <div class="NgxEditor__MenuItem NgxEditor__MenuItem--IconContainer">
    <button (mousedown)="toggleHtmlView()" class="NgxEditor__MenuItem--Icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        height="20"
        width="20"
      >
        <path
          d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"
        ></path>
      </svg>
    </button>
  </div>
</ng-template>
