import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@app/core/models/app.model';
import { AppState } from '@app/core/states/app.state';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-upgrade-offer',
  templateUrl: './upgrade-offer.component.html',
  styleUrls: ['./upgrade-offer.component.scss'],
})
export class UpgradeOfferComponent implements OnInit, OnDestroy {

  @Select(AppState.currentApp)
  currentApp$: Observable<App>;

  private readonly subscription = new Subscription();
  private serviceId = 0;
  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.currentApp$
        .subscribe(app => {
          this.serviceId = app.serviceId;
        }),
    );
  }

  upgradeOffer(): void {
    window.location.href=`${environment.whmcs.url}/upgrade.php?type=package&id=${this.serviceId}`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
