<div *ngIf="(user$ | async) === null; else content">
  <div class="w-screen h-screen flex justify-center items-center">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="100"
    ></mat-progress-spinner>
  </div>
</div>

<ng-template #content>
  <div
    class="app-container"
    [class.min-w-[1100px]]="isMinWidth$ | async"
  >
    <rk-header class="hidden md:block"></rk-header>
    <div
      class="flex content-container"
      [class.md:p-3]="showMainContainerPadding$ | async"
    >
      <rk-main-menu *ngIf="showMainMenu$ | async" [class.hidden]="showAppManagerMobileWarning$ | async" ></rk-main-menu>
      <div class="main-content h-full w-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- <div class="lg:hidden h-full">
    <rk-mobile-access-warning></rk-mobile-access-warning>
  </div> -->
</ng-template>

<rk-update-available></rk-update-available>
